import * as React from 'react';
import { MdOpenInFull } from 'react-icons/md';
import { ModalContext } from '../Modal';
import { ScheduleProps } from './Schedule.types';
import './Schedule.scss';

function ScheduleDesc({ item }: { item: ScheduleProps }) {
  const { setModal } = React.useContext(ModalContext);

  if (!item.desc) {
    return null;
  }

  return (
    <div className="schedule__desc">
      {(item.excerpt && (
        <p>
          {item.excerpt}{' '}
          <button
            className="button--popup"
            type="button"
            onClick={() => {
              setModal({
                modalOpen: true,
                children: (
                  <div className="schedule-modal">
                    <div className="schedule-modal__inner">
                      <h3 className="schedule__time h4">
                        {item.time}
                        {item.subtime && (
                          <span style={{ fontSize: '75%' }}>
                            &nbsp; - {item.subtime}
                          </span>
                        )}
                      </h3>
                      <h4 className="schedule__title h6">{item.title}</h4>
                      {item.desc && (
                        <div className="schedule__desc">{item.desc}</div>
                      )}
                    </div>
                  </div>
                ),
              });
            }}
          >
            <MdOpenInFull />
            <span className="visually-hidden">Read More</span>
          </button>
        </p>
      )) ||
        item.desc}
    </div>
  );
}

export default function Schedule({ schedule }: { schedule: ScheduleProps[] }) {
  const schedules = schedule.reduce<ScheduleProps[][]>((prev, curr) => {
    if (!prev.length) {
      return [[curr]];
    }

    const lastGroup = prev[prev.length - 1];
    if (lastGroup.length < 4) {
      lastGroup.push(curr);
      return prev;
    }

    prev.push([curr]);
    return prev;
  }, []);

  return (
    <div className="schedule section-m-b">
      <div className="schedule__inner">
        {schedules.map((scheduleGroup, i) => (
          <React.Fragment key={`group-${i}`}>
            <ol className="all">
              {scheduleGroup.map((item, j) => (
                <li key={`schedule-item-${j}`}>
                  <div className="schedule__item">
                    <div className="schedule__heading">
                      <h3 className="schedule__time h4">
                        {item.time}
                        {item.subtime && (
                          <span style={{ fontSize: '75%' }}>
                            &nbsp; - {item.subtime}
                          </span>
                        )}
                      </h3>
                      <h4 className="schedule__title h6">{item.title}</h4>
                    </div>
                    <ScheduleDesc item={item} />
                  </div>
                </li>
              ))}
            </ol>
            <ol className="desc">
              {scheduleGroup.map((item, j) => (
                <li key={`schedule-item-desc-${j}`}>
                  <div className="schedule__item">
                    <ScheduleDesc item={item} />
                  </div>
                </li>
              ))}
            </ol>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
