import * as React from 'react';
import { ModalContext } from '../Modal';
import { SpeakerProps } from './Speakers.types';
import './Speakers.scss';

export default function Speakers({ speakers }: { speakers: SpeakerProps[] }) {
  const { setModal } = React.useContext(ModalContext);

  return (
    <div className="speakers">
      <ul>
        {speakers.map((speaker) => (
          <li key={`speaker-${speaker.name}`}>
            <div className="speaker">
              <div className="speaker__img img-frame">{speaker.image}</div>
              <h3 className="h6 speaker__name">{speaker.name}</h3>
              <p className="speaker__title">{speaker.title}</p>
              <p style={{ textAlign: 'right' }}>
                <button
                  type="button"
                  className="button--read-more"
                  onClick={() => {
                    setModal({
                      modalOpen: true,
                      children: (
                        <div className="speaker-modal">
                          <div className="speaker-modal__inner">
                            <div className="speaker-modal__header">
                              <div className="speaker-modal__img img-frame">
                                {speaker.image}
                              </div>
                              <div className="speaker-modal__title">
                                <h3 className="h6 speaker-modal__name">
                                  {speaker.name}
                                </h3>
                                <p className="speaker-modal__title">
                                  {speaker.title}
                                </p>
                                <div className="speaker-modal__body hide-xs show-sm">
                                  {speaker.bio}
                                </div>
                              </div>
                            </div>
                            <div className="speaker-modal__body hide-sm">
                              <div className="speaker-modal__body__inner">
                                {speaker.bio}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    });
                  }}
                >
                  View Bio
                </button>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
