import { motion, useMotionValue, useTransform } from 'framer-motion';
import * as React from 'react';

const tickVariants = {
  // pressed: (isChecked: boolean) => ({ pathLength: isChecked ? 0.85 : 0.2 }),
  checked: { pathLength: 1 },
  unchecked: { pathLength: 0 },
};

interface RadioProps {
  id: string;
  name: string;
  value: string | number | readonly string[] | undefined;
  currentValue: string | number | readonly string[] | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  label: string | React.ReactChild | React.ReactNode;
}

export default function Radio({
  id,
  name,
  value,
  currentValue,
  onChange,
  label,
}: RadioProps) {
  // Checkmark Animation
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="checkbox-label">
        <input
          type="radio"
          id={id}
          value={value}
          className="visually-hidden"
          onChange={onChange}
          name={name}
        />
        <span className="label">
          <motion.svg
            width="22.623"
            height="17.173"
            viewBox="0 0 22.623 17.173"
            initial={false}
            animate={value === currentValue ? 'checked' : 'unchecked'}
          >
            <motion.path
              d="M853.1,344.926l4.415,4.415L868.656,338.2"
              transform="translate(-849.569 -334.668)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
              variants={tickVariants}
              style={{ pathLength, opacity }}
              // custom={value}
            />
          </motion.svg>
          {label}
        </span>
      </label>
    </>
  );
}
