import '../styles/main.scss';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
