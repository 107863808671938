import * as React from 'react';
import useOverflowScroll from '../../hooks/useOverflowScroll';

interface Props {
  className?: string;
  children: any;
  scrollToElement?: any;
}

export function OverflowScroll({
  className,
  children,
  scrollToElement,
}: Props) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLDivElement>(null);
  useOverflowScroll(wrapperRef, innerRef);

  React.useEffect(() => {
    if (wrapperRef?.current && innerRef?.current && scrollToElement?.current) {
      const { offsetLeft, clientWidth } = scrollToElement.current;
      const wrapperClientWidth = wrapperRef.current.clientWidth;
      const innerScrollLeft = innerRef.current.scrollLeft;

      const buffer = 40;
      const isOutsideView =
        innerScrollLeft >= offsetLeft ||
        offsetLeft + clientWidth >= wrapperClientWidth - buffer;

      if (isOutsideView) {
        if (offsetLeft >= wrapperClientWidth + innerScrollLeft - buffer) {
          // Stick to the right
          innerRef.current.scroll({
            left: offsetLeft - wrapperClientWidth + clientWidth + buffer,
            behavior: 'smooth',
          });
        } else {
          // Stick to the left
          innerRef.current.scroll({
            left: offsetLeft - buffer,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [scrollToElement]);

  return (
    <div
      ref={wrapperRef}
      className={`overflow-scroll ${className}`}
      style={{ display: 'flex', overflow: 'hidden' }}
    >
      <div
        ref={innerRef}
        style={{
          overflow: 'auto hidden',
          // Add spaces for scrollbar without shifting the layout
          margin: '-1rem 0',
          padding: '1rem 0',
        }}
      >
        <div
          // use display:table as alternative to width:fit-content
          style={{ display: 'table', overflow: 'hidden' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
