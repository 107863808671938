export function GoogleCalendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="186 38 76 76"
      width="76"
      height="76"
      aria-labelledby="gctitle"
      role="img"
    >
      <title id="gctitle">Add to Google Calendar</title>
      <path fill="#fff" d="M244 56h-40v40h40V56z" />
      <path fill="#EA4335" d="M244 114l18-18h-18v18z" />
      <path fill="#FBBC04" d="M262 56h-18v40h18V56z" />
      <path fill="#34A853" d="M244 96h-40v18h40V96z" />
      <path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z" />
      <path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z" />
      <path
        fill="#4285F4"
        d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z"
      />
      <path
        fill="#4285F4"
        d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"
      />
    </svg>
  );
}

export function Yahoo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 291.319 291.319"
      width="291.319"
      height="291.319"
      aria-labelledby="yahootitle"
      role="img"
    >
      <title id="yahootitle">Add to Yahoo! Calendar</title>
      <path
        fill="#720e9e"
        d="M145.659 0c80.45 0 145.66 65.219 145.66 145.66 0 80.45-65.21 145.659-145.66 145.659S0 226.109 0 145.66C0 65.219 65.21 0 145.659 0z"
      />
      <path
        fill="#fff"
        d="m212.353 114.98.155-.027 4.825-5.371-.237-.018.51-.801h-67.595l2.604 9.249h18.444l-31.044 28.722c-6.336-9.24-21.184-30.479-31.544-46.411h19.254v-6.555l.264-1.884-.264-.036v-.765H54.631v9.24H77.49c8.876 7.328 47.358 54.049 48.76 58.51.564 4.179 1.366 28.841-.291 30.698-1.994 2.868-22.814 1.32-26.483 1.593l-.137 9.058c6.7.2 26.801-.009 33.584-.009 13.364 0 36.77-.346 40.065-.082l.41-8.576-26.901-.401c-.564-3.887-1.183-28.422-.619-31.098 2.54-7.765 43.816-39.902 48.059-41.112l3.997-.901h12.472c-.001-.001 1.947-3.023 1.947-3.023zm-10.087 64.099 11.689.892 13.628-49.979c-2.276-.082-22.95-1.93-25.636-2.431l.319 51.518zm-2.021 8.012.064 12.208 5.917.492 6.391.446 1.875-11.944-6.737-.31-7.51-.892z"
      />
    </svg>
  );
}

export function AppleCalendar() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="applecalendartitle"
      role="img"
    >
      <title id="applecalendartitle">Add to Apple Calendar</title>
      <path
        d="M0 4.5C0 2.01472 2.01472 0 4.5 0H19.5C21.9853 0 24 2.01472 24 4.5V19.5C24 21.9853 21.9853 24 19.5 24H4.5C2.01472 24 0 21.9853 0 19.5V4.5Z"
        fill="white"
      />
      <path
        d="M0 4.8C0 2.14903 2.01472 0 4.5 0H19.5C21.9853 0 24 2.14903 24 4.8V8H0V4.8Z"
        fill="#E85750"
      />
      <path
        d="M7.09947 19.4416C6.56838 19.0682 6.20071 18.5228 6 17.8018L7.23268 17.273C7.34458 17.7167 7.53996 18.0606 7.81883 18.3046C8.09591 18.5487 8.4334 18.6688 8.82771 18.6688C9.23091 18.6688 9.57726 18.5413 9.86678 18.2861C10.1563 18.031 10.302 17.7056 10.302 17.3118C10.302 16.9088 10.1492 16.5797 9.84369 16.3246C9.53819 16.0694 9.15453 15.9419 8.69627 15.9419H7.98402V14.6717H8.62344C9.01776 14.6717 9.34991 14.5608 9.6199 14.3389C9.88988 14.1171 10.0249 13.8139 10.0249 13.4275C10.0249 13.0836 9.90409 12.81 9.66252 12.6048C9.42096 12.3995 9.11546 12.296 8.74423 12.296C8.38189 12.296 8.09414 12.3958 7.88099 12.5974C7.66799 12.7994 7.50785 13.0545 7.41563 13.3387L6.19538 12.81C6.35702 12.333 6.65364 11.9115 7.08881 11.5472C7.52398 11.183 8.07993 11 8.75489 11C9.254 11 9.70338 11.0998 10.1012 11.3014C10.4991 11.5029 10.8117 11.782 11.0373 12.137C11.2629 12.4938 11.3748 12.8932 11.3748 13.3369C11.3748 13.7898 11.27 14.1726 11.0604 14.4868C10.8508 14.8011 10.5932 15.0415 10.2877 15.2097V15.2855C10.6822 15.4548 11.0249 15.7329 11.2789 16.0898C11.5364 16.4503 11.6661 16.881 11.6661 17.3839C11.6661 17.8868 11.5435 18.3361 11.2984 18.7299C11.0533 19.1237 10.714 19.4343 10.2842 19.6598C9.85258 19.8854 9.36767 20 8.82948 20C8.20604 20.0018 7.63055 19.8151 7.09947 19.4416ZM14.6714 13.0744L13.3179 14.0931L12.6412 13.0244L15.0693 11.2015H16V19.8003H14.6714V13.0744Z"
        fill="#575757"
      />
    </svg>
  );
}

export function Outlook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 103.173 104.313"
      width="103.173"
      height="104.313"
      aria-labelledby="outlooktitle"
      role="img"
    >
      <title id="outlooktitle">Add to Outlook</title>
      <path
        fill="#0072c6"
        d="M64.567 22.116v20.405l7.13 4.49c.188.054.596.058.784 0l30.688-20.69c0-2.45-2.284-4.205-3.573-4.205h-35.03z"
      />
      <path
        fill="#0072c6"
        d="m64.567 50.133 6.507 4.47c.917.674 2.022 0 2.022 0-1.101.674 30.077-20.036 30.077-20.036V72.07c0 4.083-2.613 5.795-5.551 5.795h-33.06v-27.73z"
      />
      <g fill="#0072c6">
        <path d="M30.873 40.726c-2.218 0-3.985 1.042-5.29 3.123-1.304 2.08-1.958 4.834-1.958 8.263 0 3.479.654 6.229 1.959 8.25 1.304 2.026 3.016 3.033 5.132 3.033 2.182 0 3.914-.983 5.191-2.95 1.278-1.967 1.92-4.698 1.92-8.188 0-3.64-.619-6.473-1.861-8.498-1.242-2.022-2.938-3.033-5.093-3.033z" />
        <path d="M0 11.754V91.58l60.727 12.733V0L0 11.754zm40.636 53.408c-2.566 3.377-5.912 5.07-10.041 5.07-4.024 0-7.3-1.638-9.834-4.91-2.531-3.275-3.8-7.537-3.8-12.795 0-5.552 1.285-10.042 3.859-13.47 2.574-3.428 5.982-5.144 10.225-5.144 4.008 0 7.252 1.638 9.724 4.92 2.476 3.284 3.715 7.61 3.715 12.98.003 5.521-1.282 9.972-3.848 13.349z" />
      </g>
    </svg>
  );
}

export function Office365() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48"
      height="48"
      aria-labelledby="officetitle"
      role="img"
    >
      <title id="officetitle">Add to Office365</title>
      <path
        fill="#e64a19"
        d="m7 12 22-8 12 3v34l-12 3-22-8 22 3V10l-14 3v20l-8 3z"
      />
    </svg>
  );
}
