export function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function formatDate(date: string, ignoreSameYear?: boolean) {
  const dateObject = new Date(date);
  const options = ignoreSameYear
    ? ({ month: 'short', day: 'numeric' } as const)
    : ({ year: 'numeric', month: 'short', day: 'numeric' } as const);
  return dateObject.toLocaleDateString('en-US', options);
}

export function truncate(str: string, n: number, useWordBoundary?: boolean) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }&hellip;`;
}

export function removeEmptySpaces(str: string) {
  return str.replace(/(\r\n|\n|\r)/gm, '');
}
