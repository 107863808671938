import * as React from 'react';
import { MdInfo } from 'react-icons/md';
import useOnClickOutside from '../../hooks/useOnClickOutside';

export default function FormMessageTooltip({
  label,
  message,
}: {
  label: string;
  message: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [tooltipShown, setTooltipShown] = React.useState<boolean>(false);
  useOnClickOutside(ref, () => setTooltipShown(false));

  return (
    <div
      ref={ref}
      className="form-field-message form-field-message--tooltip small"
    >
      <button
        className={`tooltip-trigger ${tooltipShown ? 'tooltip-clicked' : ''}`}
        type="button"
        onClick={() => setTooltipShown(!tooltipShown)}
      >
        {label} <MdInfo />
      </button>
      <div
        className="tooltip"
        style={{
          opacity: tooltipShown ? 1 : 0,
          pointerEvents: tooltipShown ? 'auto' : 'none',
        }}
      >
        {message}
      </div>
    </div>
  );
}
