/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import type { GatsbyBrowser } from 'gatsby';
import { Layout as CommonLayout } from '@augustine-institute/lib';
import Layout from './src/components/Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <CommonLayout>
    <Layout {...props}>{element}</Layout>
  </CommonLayout>
);
