import * as React from 'react';

interface WindowSizeProps {
  width: number | undefined;
  height: number | undefined;
}

export default function useWindowSize(debounce?: boolean, handler?: Function) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<WindowSizeProps>({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    if (handler) {
      if (debounce) {
        const delayDebounceFn = setTimeout(() => {
          handler();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }
      handler();
    }
  }, [windowSize]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    if (handler) {
      handler();
    }
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return windowSize;
}
