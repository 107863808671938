import * as React from 'react';
import { slugify } from '../../utils/helpers';
import { OverflowScroll } from './OverflowScroll';

interface TabPanelProps {
  index?: number;
  wrapperId?: string;
  children?: any;
  activeTab?: number;
}

export function TabPanel({
  index,
  wrapperId,
  children,
  activeTab,
}: TabPanelProps) {
  const tabId = `${wrapperId}-${index}`;
  const isActive = `${wrapperId}-${activeTab}` === tabId;

  return (
    <div
      id={tabId}
      className={`tabs__tab-panel ${isActive ? 'tabs__tab-panel--active' : ''}`}
      role="tabpanel"
      aria-labelledby={`tab-${tabId}`}
    >
      {children}
    </div>
  );
}

interface TabPanelWrapperProps {
  wrapperId?: string;
  children?: any;
  activeTab?: number;
}

export function TabPanelWrapper({
  wrapperId,
  children,
  activeTab,
}: TabPanelWrapperProps) {
  return (
    <div className="tabs__tab-panel-wrapper">
      {React.Children.map(children, (child, i) => (
        <TabPanel
          key={`${wrapperId}-tabpanel-${i}`}
          wrapperId={wrapperId}
          index={i}
          activeTab={activeTab}
        >
          {child.props.children}
        </TabPanel>
      ))}
    </div>
  );
}

interface TabProps {
  id?: string;
  children?: any;
  index?: number;
  wrapperId?: string;
  activeTab?: number;
  setActiveTab?: Function;
  setActiveTabRef?: Function;
}

export function Tab({
  id,
  children,
  wrapperId,
  index,
  activeTab,
  setActiveTab,
  setActiveTabRef,
}: TabProps) {
  const handleClick = () => {
    if (typeof setActiveTab === 'function') {
      setActiveTab(index);
    }
  };
  const tabId = `${wrapperId}-${index}`;
  const isActive = `${wrapperId}-${activeTab}` === tabId;
  const tabRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (isActive && tabRef.current && setActiveTabRef instanceof Function) {
      setActiveTabRef(tabRef);
    }
  }, [isActive]);

  return (
    <li
      id={`tab-${tabId}`}
      data-id={id}
      className={`tabs__tab ${isActive ? 'tabs__tab--active' : ''}`}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      aria-selected={isActive}
      aria-controls={tabId}
      onClick={handleClick}
      onKeyPress={handleClick}
      ref={tabRef}
    >
      {children}
    </li>
  );
}

export function TabList({
  wrapperId,
  children,
  activeTab,
  setActiveTab,
  setActiveTabRef,
}: any) {
  function handleKeyDown(e: React.KeyboardEvent) {
    const count = children.length;

    if (e.key === 'ArrowRight') {
      const nextTab = activeTab < count - 1 ? activeTab + 1 : 0;
      setActiveTab(nextTab);
    }

    if (e.key === 'ArrowLeft') {
      const prevTab = activeTab > 0 ? activeTab - 1 : count - 1;
      setActiveTab(prevTab);
    }
  }

  return (
    <ul className="tabs__tab-list" role="tablist" onKeyDown={handleKeyDown}>
      {React.Children.map(children, (child, i) => (
        <Tab
          key={`${wrapperId}-tab-${i}`}
          wrapperId={wrapperId}
          index={i}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setActiveTabRef={setActiveTabRef}
        >
          {child.props.children}
        </Tab>
      ))}
    </ul>
  );
}

interface TabsProps {
  id: string;
  defaultTab?: number | string;
  children?: any;
}

export function Tabs({ children, id, defaultTab }: TabsProps) {
  const wrapperId = slugify(id);
  // Get Default Tab Index
  // const [ defaultTabIndex, setDefaultTabIndex ] = useState<number>(0);
  let defaultTabIndex: number = 0;

  if (typeof defaultTab === 'string') {
    const tabList = children.find(
      (child: React.ReactElement) => child.type === TabList
    );
    const tabs = tabList?.props.children;
    defaultTabIndex =
      tabs?.length > 0
        ? tabs.findIndex(
            (child: React.ReactElement) => child.props.id === defaultTab
          )
        : 0;
  } else {
    defaultTabIndex = defaultTab || 0;
  }

  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabRef, setActiveTabRef] = React.useState(null);

  React.useEffect(() => {
    setActiveTab(defaultTabIndex);
  }, [defaultTab]);

  return (
    <div className="tabs">
      {React.Children.map(children, (child) => {
        if (child.type === TabList) {
          return (
            <OverflowScroll
              className="tabs__tab-list-wrapper"
              scrollToElement={activeTabRef}
            >
              <TabList
                key={`${wrapperId}-tablist`}
                wrapperId={wrapperId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setActiveTabRef={setActiveTabRef}
              >
                {child.props.children}
              </TabList>
            </OverflowScroll>
          );
        }
        if (child.type === TabPanelWrapper) {
          return (
            <TabPanelWrapper
              key={`${wrapperId}-tabpanelwrapper`}
              wrapperId={wrapperId}
              activeTab={activeTab}
            >
              {child.props.children}
            </TabPanelWrapper>
          );
        }
      })}
    </div>
  );
}
